import { Controller } from "@hotwired/stimulus";

///////////////////////////////////
// Implements color picker
///////////////////////////
export default class extends Controller {
  static values = {
    url: String
  }
  
  connect() {
    const parent = this.element;
    const url = this.urlValue;

    const turboStreamRequest = (url, method = 'GET', body = null) => {
      return fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'text/vnd.turbo-stream.html',
          'Authorization': `Bearer ${window.sessionToken}`
        },
        body: method === 'GET' ? null : JSON.stringify(body)
      })
      .then(response => response.text())
      .then(turboStreamResponse => Turbo.renderStreamMessage(turboStreamResponse))
      .catch(error => {
        console.error('Turbo Stream Request failed', error);
      });
    };

    parent.classList.add('clickable');
    parent.addEventListener("click", event => {
      if(event.target.tagName != 'LABEL' && event.target.tagName != 'INPUT'){
        var checkbox = parent.querySelector('input[type="checkbox"]');
        checkbox.checked = !checkbox.checked;
      }
      if(event.target.tagName != 'INPUT'){
        turboStreamRequest(url);
      }
    });
  }
}
