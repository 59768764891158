import { Controller } from "@hotwired/stimulus";

///////////////////////////////////////
// Implements tabs.
// Event name can be changed from click to something else (i.e. change) to implement
// tabs on radio buttons or other elements
////////////////////////////////////
export default class extends Controller {
  static values = {
    url: String,
    removeUrl: String,
    type: String,
    form: { type: String, default: "" }
  };

  connect() {
    var removeUrl = this.removeUrlValue;
    var typeValue = this.typeValue;

    var options = { 
      url: this.urlValue + `&type=${this.typeValue}`,
      maxFilesize: 32,
      resizeWidth: 800,
      addRemoveLinks: true
    }

    var fileType = "image/*";
    if(typeValue == "pdf"){
      options.acceptedFiles = "application/pdf"
    }else if(typeValue == "csv"){
      options.acceptedFiles = "text/csv"
    }
    
    var dropzone = new Dropzone(this.element, options);

    var form = document.getElementById(this.formValue);

    dropzone.on("addedfile", () => {
      if(form){
        form.requestSubmit();
      }
    })

    dropzone.on("error", (file, message) => {
      $(".dz-success-mark").html('');
    })

    dropzone.on("removedfile", (file) => {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.open("post", removeUrl + `&type=${typeValue}&name=${file.name}&size=${file.size}`); 
      xmlHttp.send();
    })

    document.getElementsByClassName("dz-remove").forEach(function (el) {
      el.addEventListener("click", (event) => {
        var fileID = el.getAttribute("data-dz-remove");
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open("post", removeUrl + `&type=${typeValue}&file_id=${fileID}`);
        xmlHttp.send();

        var container = document.querySelector(`.dz-preview-${fileID}`);
        if(container){
          container.parentNode.removeChild(container);
        }
      });
    });
  }
}
